@import '../../styles/mixins';

$largest-size: 1440px;
$large-size: 1280px;
$medium-size: 1024px;
$small-size: 768px;
$small-mid-size: 667px;
$smallest-size: 480px;
$ultra-smallest-size: 375px;

$transition-slow: 450ms ease;
$transition-base: 300ms ease;

$font-size-base: 16;
$font-family-base: 'Open Sans', sans-serif;
$font-family-solid: 'Montserrat', sans-serif;
$font-family-italic: 'Libre Baskerville', sans-serif;

$color-blue: #006CA9;
$color-orange: $color-blue;
$color-orange-dark: $color-blue;
$color-orange-1: #FF6B58;
//$color-orange-dark: #c94a24;

$color-laguna: #00A3AF;
$color-laguna-light: #F2FAFB;
$color-laguna-dark: #007178;
//$color-laguna-dark: #00ADB5;

$color-grey-light: #adadad;
$color-grey: #676767;

$primary-1: #ae0032;
$primary-2: #455162;
$primary-3: #1f2f46;

$neutral-1: #0d1720;
$neutral-2: #636363;
$neutral-3: #dadada;
$neutral-4: #f2f2f2;
$neutral-5: #fcfbfa;

.error-hero {
    position: relative;
}
.error-hero__bgs {
    height: auto;

    bottom: -50px;

    @media (min-width: $small-size) {
        bottom: -100px;
    }
    @media (min-width: $large-size) {
        bottom: -70px;
    }
    @media (min-width: $largest-size) {
        bottom: -150px;
    }
}
.error-hero__bg {
    width: 200px;
    height: 200px;

    bottom: 0;
    transform: translateX(-30%);

    @media (min-width: $small-size) {
        width: 250px;
        height: 250px;
    }
    @media (min-width: $medium-size) {
        width: 350px;
        height: 350px;
    }
    @media (min-width: $large-size) {
        width: 400px;
        height: 400px;
    }
    @media (min-width: $largest-size) {
        width: 420px;
        height: 420px;
    }
}

.error-hero__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $small-size) {
        padding-bottom: 200px;
    }
    @media (min-width: $largest-size) {
        padding-bottom: 150px;
    }
}
.error-hero__heading {
    margin-bottom: 20px;

    color: $color-laguna;
}
.error-hero__text {
    max-width: 640px;

    text-align: center;
}
.error-hero__btn {
    margin-top: 20px;

    @include btn__color-orange-dark;

    @media (min-width: $small-size) {
        margin-top: 40px;
    }
}